<template>
	<div>
		<div class="sub-tit" ref="title"><p>관심인재 등록기업</p></div>
		<div class="Board type3 reading">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="6%">
					<col width="30%">
					<col width="32%">
					<col width="32%">
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>관심인재 등록일</th>
						<th>기업명</th>
						<th>담당자</th>
					</tr>								
				</thead>
				<tbody>

					<tr  v-for="(inter, index) in list" :key="inter.corpMberSeq">
						<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
						<td>{{inter.interestRegDate}}</td>
						<td>{{inter.corpNm}}</td>
						<td>{{inter.corpMberNm}}</td>
					</tr>
					<tr v-if="pageInfo.totalRecordCount == 0">
						<td colspan="5" class="none">관심인재로 등록한 기업이 없습니다!</td>
					</tr>

				</tbody>
			</table>

			<!--페이징 컴포넌트 -->
			<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
			<!--// 페이징 컴포넌트 -->

		</div>
	</div>
</template>

<script>
import PagingComp from '@/components/PagingComp.vue';
export default {
	components : { PagingComp },
	data() {
		return {
			input: {
				tecMberSeq: this.$route.query.tecMberSeq,
				pageIndex : '1',
			}, // 검색조건		
			list : [],
			pageInfo : {},
			
		};
	},
	mounted() {
		// console.log('mounted');
		this.getList();
	},
	methods: {
		getList() {
			if(this.input.tecMberSeq){
				this.$.httpPost('/api/mem/prf/getInterestMberList', this.input)
					.then(res => {
						//console.log(res.data);
						this.list = res.data.list;
						this.pageInfo = res.data.pageInfo;

						this.$emit('searchEnd', res.data);
					}).catch(this.$.httpErrorCommon);
			}
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},
	},
};
</script>
